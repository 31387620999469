import './Governance.css';
import {useState, useEffect, useLayoutEffect} from 'react';
import headerimage from './images/parliament.jpg';



function Governance(){


    const [imageHeight, setImageHeight] = useState(0);
    const topPosition = imageHeight * 0.7;

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const handleResize  = () => {
        const image = document.getElementsByClassName('governance-header-image')[0];
        if (image) {
          setImageHeight(image.offsetHeight);
        }}

        useEffect(() => {
            handleResize();
        
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        })

    return(
        <div className='Governance'>
            <div className='governance-header'>
                <img src={headerimage} className='governance-header-image' alt="houses of parliament"/>
                <div className='governance-header-wrapper'
                style={{ top: `${topPosition}px` }}
                >
                <div className='governance-header-title'>
                    <h2>Governance</h2>
                </div>
                </div>
            </div>
            <div className='governance-content'>
            <div className='governance-section-one'>
                <h4> Leading with integrity </h4>
                <p> At Aptius, our governance infrastructure is meticulously designed with industry standards embedded throughout our processes, ensuring that we remain auditable and accountable to the highest levels. While our financial products fall within the corporate bond categorizations and are outside the direct oversight of the FCA, we nonetheless hold ourselves to the same ethical standards and rigor expected of a fully FCA-authorized organization. We are committed to leading by example, adopting ethical practices, upholding a strong commitment to professionalism, and consistently implementing high standards and transparent collateralization policies. No matter the formal requirements, we strive to exceed expectations in every aspect of our governance.</p>
            </div>
            <div className='governance-section-two'>
            <h4> Commitment to exemplary governance </h4>
            <p> 
            At Aptius, our commitment to governance goes beyond merely meeting requirements—we aim to set a world-class standard. Our policies are designed to evolve continuously, allowing us to incorporate new legislation in an ever-changing regulatory landscape. For us, exemplary governance is not just about compliance; it’s about prioritizing integrity and adopting practices that exceed standard expectations. Our core values—Resilience, Courage, and Integrity—are the foundation of every decision we make. We pride ourselves on our agility, responding swiftly to market conditions with our stakeholders’ interests at the forefront of every commercial decision. Building on our rapid success, our recent migration to AWS cloud has significantly enhanced our process management, enabling us to scale effectively and grow our team. Leveraging AWS for automation and machine learning has been crucial in improving our revenue operations and market response times. We also maintain a strong focus on security, regularly conducting penetration testing on our trading systems and collateralized environments. With no medium or high-risk vulnerabilities found, our robust security practices and failover frameworks ensure the safety and resilience of our operations.
            </p>
            </div>
            </div>
        </div>
    );
}

export default Governance;
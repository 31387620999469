import './navbar.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Navbar() {
  //this is done for both onClick and resize events
  const handleNavbarExpand = () => {
    const navbar = document.querySelector(".Navbar");
    const navbarExpanded = document.querySelector(".Navbar-Expanded");
    navbarExpanded.classList.toggle('navToggle')
    const navToggle = navbarExpanded.classList.contains('navToggle')
    if (window.innerWidth < 1024) {
      navbarExpanded.style.top = '0px';
          if (navToggle) {
            navbarExpanded.style.left = '0px';
            document.body.style.position = 'fixed';
        } else {
            navbarExpanded.style.left = '-100%';
            document.body.style.position = 'initial';
        }
    } else {
        navbarExpanded.style.left = '0px';
        document.body.style.position = 'initial';
        if (navToggle) {
            navbarExpanded.style.top = `${navbar.offsetHeight - 2}px`;
        } else {
            navbarExpanded.style.top = `${-navbar.offsetHeight - 2}px`;
        }
    }
}

// useEffect(() => {
//   window.addEventListener('resize', handleNavbarExpand)
// }, [])

  return (
    <>
    <div className='Navbar'>
      <div className='navbar-about desktop' onClick={handleNavbarExpand}>
        Menu
      </div>
      <div className='navbar-brand'>
        <Link to='/'> 
        <img className="navbar-brand-logo" alt="" src={process.env.PUBLIC_URL + '/assets/logo.svg'}/>
        <h1 className="navbar-brand-title">Aptius</h1>
        </Link>
      </div>
      <Link to='/contact' className='navbar-contact desktop'>Contact</Link>
      <div className='navbar-button mobile'>
      <img alt="" src={process.env.PUBLIC_URL + '/assets/navbar/menu.svg'} onClick={handleNavbarExpand}/>
      </div>
    </div>
    <div className='Navbar-Expanded'>
      <div className="navbar-button">
      <img alt="" src={process.env.PUBLIC_URL + '/assets/navbar/menu-close.svg'} onClick={handleNavbarExpand}/>
    </div>
    <ul className='navbar-list'>
      <li>
        <Link to='/vision' onClick={handleNavbarExpand}>Our Vision</Link>
        <img src={process.env.PUBLIC_URL + '/assets/navbar/yellow-arrow.svg'} alt=""/>
      </li>
      <li>
        <Link to='/technology' onClick={handleNavbarExpand}>Technology</Link>
        <img src={process.env.PUBLIC_URL + '/assets/navbar/yellow-arrow.svg'} alt=""/>
      </li>
      <li>
        <Link to='/governance' onClick={handleNavbarExpand}>Governance</Link>
        <img src={process.env.PUBLIC_URL + '/assets/navbar/yellow-arrow.svg'} alt=""/>
      </li>
      {/* <li>
        <Link to='/charity' onClick={handleNavbarExpand}>Charity</Link>
        <img src={process.env.PUBLIC_URL + '/assets/navbar/yellow-arrow.svg'} alt=""/>
      </li> */}
      <li>
        <Link to='/risk' onClick={handleNavbarExpand}>Security and Risk</Link>
        <img src={process.env.PUBLIC_URL + '/assets/navbar/yellow-arrow.svg'} alt=""/>
      </li>
    </ul>
    <Link to='/contact' onClick={handleNavbarExpand} className='navbar-contact-button'>
      Contact Us
    </Link>
    </div>
    </>
  );
};

  export default Navbar;
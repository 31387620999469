import React, { useState} from "react";
import { Link } from "react-router-dom";
import './Home.css';
import Modal from "../../components/modal/Modal";
import Promo from "../../components/promo/Promo";
import Roadmap from "../../components/roadmap/AptiusStorySection";
import arrow from './arrow.svg';

function Home() {

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPerson, setModalPerson] = useState('');

//   useLayoutEffect(() => {
//     window.scrollTo(0, 0)
// });

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  
    return (
      <div className="Home">
      <Modal isOpen={modalOpen} onClose={closeModal} person={modalPerson}/>
        <div className="home-container"
        id="homepage">
        <div className='main-section'>
          <div className='main-section-text'>
            <h2>Aptius</h2>
            <span className="main-section-line"></span>
            <p>make change your asset</p>
            </div>
        </div>
        <Promo section={"ourvision"}/>
        <div className='roadmap-section'>
          <Roadmap/>
        </div>
        <div className='team-section'>
          <h3 className='team-section-header'>The Founding Team</h3>
          <p className='team-section-text'>We believe that optimal results stem from a multidisciplinary, experienced team that can view the world through a fresh lens when needed. Our collective expertise blends regulatory acumen, technical innovation, and adaptive management, ensuring we are well-equipped to navigate challenges and drive success.</p>
          <div className='team-section-inner'>
            <div className='team-section-inner-card'>
              <div className='team-section-inner-card-image'>
              <img alt='' src={process.env.PUBLIC_URL + '/assets/main/people/daniel.jpg'}  onClick={ window.innerWidth > 760 ? () => {setModalPerson('daniel'); openModal();} : null}   />
              <span onClick={ window.innerWidth > 760 ? () => {setModalPerson('daniel'); openModal();} : null}></span>
              </div>
              <div className='team-section-inner-card-name'>
                <h4>Daniel M. Ashworth </h4>
                <div className='team-section-inner-card-name-line'></div>
                <h5>CEO and Co-Founder</h5>
                <div className='team-section-inner-card-name-readmore'
                onClick={() => {setModalPerson('daniel'); openModal();}}>
                  <p>Read more</p>
                  <img alt='' src={process.env.PUBLIC_URL + '/assets/main/blue-right-arrow.svg'}/>
                </div>
              </div>
            </div>
            <div className='team-section-inner-card'>
              <div className='team-section-inner-card-image'>
              <img alt=''
              src={process.env.PUBLIC_URL + '/assets/main/people/cornell.jpg'}  onClick={ window.innerWidth > 760 ? () => {setModalPerson('cornell'); openModal();} : null} />
              <span onClick={ window.innerWidth > 760 ? () => {setModalPerson('cornell'); openModal();} : null}></span>
              </div>
              <div className='team-section-inner-card-name'>
                <h4>Cornell Campbell</h4>
                <div className='team-section-inner-card-name-line'></div>
                <h5>Chief Revenue Officer</h5>
                <div className='team-section-inner-card-name-readmore' onClick={() => {setModalPerson('cornell'); openModal();}}>
                  <p>Read more</p>
                  <img alt='' src={process.env.PUBLIC_URL + '/assets/main/blue-right-arrow.svg'}/>
                </div>
              </div>
            </div>
            <div className='team-section-inner-card'>
              <div className='team-section-inner-card-image'>
              <img alt=''
              src={process.env.PUBLIC_URL + '/assets/main/people/jonathan.jpg'}  onClick={ window.innerWidth > 760 ? () => {setModalPerson('jonathan'); openModal();} : null}/>
              <span onClick={ window.innerWidth > 760 ? () => {setModalPerson('jonathan'); openModal();} : null}></span>
              </div>
              <div className='team-section-inner-card-name'>
                <h4>Jonathan Peters</h4>
                <div className='team-section-inner-card-name-line'></div>
                <h5>CTO and Co-founder</h5>
                <div className='team-section-inner-card-name-readmore' onClick={() => {setModalPerson('jonathan'); openModal();}}>
                  <p>Read more</p>
                  <img alt='' src={process.env.PUBLIC_URL + '/assets/main/blue-right-arrow.svg'}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Promo section={"technology"}/>
        <Promo section={"governance"}/>
        <Promo section={"risk"}/>
        
        <div className="main-contact">
          <div >
          <h4>Connect with us</h4>
          <p>If you would like to learn more about Aptius as an organisation, or for press enquiries, please use our website contact form to get in touch. </p>
          </div>
          <Link to={'/contact'}>
            Contact Us
            <img alt='' src={arrow} />
          </Link>
        </div>
        </div>
      </div>
    );
  }
  
  export default Home;
  
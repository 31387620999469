import firstImage from './assets/image-1.png'
import secondImage from './assets/image-2.png'
import thirdImage from './assets/image-3.png'
import fourthImage from './assets/image-4.png'
import fifthImage from './assets/image-5.png'

import { v4 as uuidv4 } from 'uuid'

const milestoneItems = [
    {
        id: uuidv4(),
        storyHeader: 'Small Beginnings',
        storyParagraph: 'Combining expertise from the Swiss private banking sector with the research and development strengths of The University of Exeter, we developed our software, which has now progressed into beta testing.',
        year: 2017,
        storyImage: firstImage,
    },
    {
        id: uuidv4(),
        storyHeader: 'Aptius is Founded',
        storyParagraph: 'Aptius Ltd was established in June 2018. Though our beginnings were modest, the beta version of our software has since evolved into version 1.0. The combined experience and research expertise of our founders have transformed Aptius Ltd into a full-time, thriving venture.',
        year: 2018,
        storyImage: secondImage,
    },
    {
        id: uuidv4(),
        storyHeader: 'Changing Times, A Turning Point',
        storyParagraph: 'During the COVID-19 lockdown, Aptius leveraged technology to optimize operations, scaling development and implementing specialized algorithms that enhanced our capabilities and positioned us for future growth.',
        year: 2020,
        storyImage: thirdImage,
    },
    {
        id: uuidv4(),
        storyHeader:'Rapid Growth Amid Continued Change',
        storyParagraph: 'Amid conflict in Ukraine, an energy crisis, rising inflation, and global turbulence, Aptius effectively navigated change, leveraging 2020/21 growth and enhanced algorithms to significantly expand our holdings.',
        year: 2022,
        storyImage: fourthImage,
    },
    {
        id: uuidv4(),
        storyHeader: 'Anchored for the Future',
        storyParagraph: 'As Aptius enters its sixth year, with over £2.9 million in company holdings, we have embarked on our ‘Aptius 2028’ plan. With a long-term vision, our goal is to exceed £50 million in holdings by 2028.',
        year: 2024,
        storyImage: fifthImage,
    }
]

export default milestoneItems;
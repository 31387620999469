import { Link } from 'react-router-dom';
import './footer.css';


function Footer() {
    return (
      <div className='Footer'>
        <div className='footer-pages'>
          <h4 className='footer-pages-header'>
            Menu
          </h4>
          <div className='footer-pages-list'>
            <ul>
              <li>
                <Link to={'/vision'}>
                Our Vision
                </Link>
              </li>
              <li>
              <Link to={'/technology'}>
                  Technology
                </Link>
                </li>
              <li>
              <Link to={'/governance'}>
                Governance
                </Link>
              </li>
              {/* <li>
              <Link to={'/charity'}>
                Charity
                </Link>
              </li> */}
              <li>
              <Link to={'/risk'}>
                Security and Risk
                </Link>
              </li>
              <li>
              <Link to={'/contact'}>
                Contact
                </Link>              
              </li>
            </ul>
          </div>
        </div>
        <div className='footer-bio'>
          <Link to={'/s'} className='footer-brand'>
          <img className='footer-brand-logo' alt='' src={process.env.PUBLIC_URL + '/assets/logo.svg'}/>
            <h2 className='footer-brand-title'>Aptius</h2>
          </Link>
          {/* <div className='footer-bio-text'>
            <p>Making change your asset.      </p>
          </div> */}
        </div>
      </div>
    );
  }
  
  export default Footer;
  
import React, { useState, useEffect, useLayoutEffect } from 'react';
import './Technology.css';
import headerimage from './images/technology.jpg';
import img4 from './images/fourth-background.jpg';

function Technology(){

    const [imageHeight, setImageHeight] = useState(0);
    const topPosition = imageHeight * 0.7;

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const handleResize  = () => {
        const image = document.getElementsByClassName('technology-header-image')[0];
        if (image) {
          setImageHeight(image.offsetHeight);
        }}

        useEffect(() => {
            handleResize();
        
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        })

    return (
        <div className='technology'>
            <div className='technology-header'>
                <img src={headerimage} className='technology-header-image' alt=""/>
                <div className='technology-header-wrapper'
                style={{ top: `${topPosition}px` }}
                >
                <div className='technology-header-title'>
                    <h2>Technology</h2>
                </div>
                </div>
            </div>
            <div className='technology-content'>
                <div className='technology-intro'>
                    <h4>“Software is a great combination between artistry and engineering.” – Bill Gates</h4>
                    <p>Our software is the culmination of seven years of continuous refinement, building on the solid foundations laid by our initial version 1.0. Through an extensive programming framework, we have integrated a comprehensive suite of custom analysis tools, flexible deployment timelines, and advanced AI-driven analytical techniques, placing us at the forefront of digital technology.</p>
                </div>
                <div className='technology-info-one'>
                    <h4> Advanced Market Analysis Techniques </h4>
                    <p> Since our inception in 2017, Aptius has been shaped by the expertise of our founders in elite private banking, FX/Crypto trading, computer programming, and research &amp; development at the University of Exeter. Over seven years, we’ve invested more than 10,000 hours in development and testing, producing over 100,000 lines of code to create the latest iterations of our software—a sophisticated tool for market trend analysis and positional trading. 
                    <br />
                    <br />    
                    Our cutting-edge automation and machine learning suites leverage custom-optimized volume and price analysis tools to pinpoint critical market zones. Specialized software divisions within Aptius focus on different timescales, from macroeconomic trends to micro-level trades within individual days, each utilizing a distinct set of optimized analytical techniques.
                    </p>
                </div>
                <div className='technology-info-two'>
                    <h4> Our Technology in Numbers</h4>
                    <ul className='stats-list'>
                        <li>
                            <div className='stat-figure'>
                                <span className='stat-part-one'>$2</span>
                                <span className='stat-part-two'>Trn</span>
                            </div>
                            <p className='stat-desc'>Market Capitalisation</p>
                        </li>
                        <li>
                            <div className='stat-figure'>
                                <span className='stat-part-one'>25</span>
                                <span className='stat-part-two'>x</span>
                            </div>
                            <p className='stat-desc'>Market Increase since 2019</p>
                        </li>
                        <li id='last'>
                            <div className='stat-figure'>
                                <span className='stat-part-one'>42,000 </span>
                                <span className='stat-part-two'>hours</span>
                            </div>
                            <p className='stat-desc'>Of trading and development</p>
                        </li>
                    </ul>
                </div>
                <div className='technology-info-three'>
                    <h4>Quality and Precision in Refinement</h4>
                    <p>Our systems’ performance is not just measured by the amount of code or the number of features but by the quality of development. We’ve dedicated countless hours to optimizing, refining, and upgrading our codebase. This rigorous process allows us to analyze every aspect of our trading performance using big data techniques, identifying key strengths and areas for improvement. Each insight is carefully refined, thoroughly tested, and meticulously implemented, resulting in continuously improved versions of our software.</p>
                </div>
                <div className='technology-info-four'>
                    <h4> Adaptable and Robust frameworks</h4>
                    <img src={img4} alt=""/>
                    <p><span>Since the launch of our initial software version in 2017, we’ve witnessed seven years of significant change and uncertainty in the financial markets. What began as a small beta version has evolved alongside these unprecedented shifts, providing us with a unique opportunity to refine our systems in response to complex and ever-changing environments. </span>
                    <br />
                    <br />
                    This dynamic landscape has allowed us to develop workflows that are not only more robust and adaptable but also focused on safety and resilience. Our software is now better equipped to handle and capitalize on unexpected market conditions, making it stronger and more reliable than ever.</p>
                </div>
          </div>
        </div>
    )
}

export default Technology;
import React, { useEffect } from 'react';
import './Modal.css';

const ModalContent = {

  daniel: {
    fname: 'Daniel M. Ashworth',
    position: 'CEO and Co-Founder',
    pic: 'daniel.jpg',
    bio: "I'm a Somerset based businessman and former regional politician, having originally worked in the private banking sector in Zurich, Switzerland. My time in Zurich, forged specialist experience within the Foreign Exchange and Capital Markets, Commodities and commercial portfolio consultancy. Back in 2018, I decided to progress into the world of independent commercial investment and jointly founded Aptius, with Jonathan Peters. Experience in the Swiss financial sector, and the complex, international and ever changing environment within private banking acted as the catalyst for an independent career, using said expertise to underwrite the creation of Aptius. Anchoring our growth in diversified experience equips us for changing nature of international markets and is key to producing results in an increasingly turbulent geopolitical environment."
  },

  jonathan: {
    fname: 'Jonathan Peters',
    position: 'CTO and Co-Founder',
    pic: 'jonathan.jpg',
    bio: "A financial technology entrepreneur, programmer and trader. Over the last 12 years I have developed proficiency in over 10 computer programming languages including C++, Java and Python. I then studied computer science at the University of Exeter, where my interest and skills were combined with those of my colleague and co-founder Mr Daniel Marcos-Ashworth, producing the genesis version of our software. I have been engaged with the crypto and FX markets ever since, with now over 7 years of market experience in trading and algorithmic software management. Studying a degree in Economics has further developed my practical and theoretical knowledge."
  },

  cornell: {
    fname: 'Cornell Campbell',
    position: 'Chief Commercial Officer',
    pic: 'cornell.jpg',
    bio: "Having had a successful career in tech and management consultancy, I have been privileged enough to travel the world meeting some exceptionally talented people, supporting; Government Agencies, Banks and Blue Chip Enterprise’ leverage cutting edge technology. Bringing to the team a very diverse background and a wealth of exposure to Central Government Defence and Banking process automation; from biometric facial recognition/matching systems enabling global security watchlisting systems controlling international borders to KYC banking applications and global procurement carbon accounting programmes. I bring a wealth of knowledge in and around secure infrastructure, global partnership frameworks and scaling startups to merger and acquisition."
  }
}

const Modal = ({ isOpen, onClose, person }) => {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.getElementById("homepage").classList.add('blurred');
      document.getElementsByClassName("modal-content")[0].addEventListener('click', function(e) {
        if (!e.target.classList.contains('close')){
          e.stopPropagation();
        }
    });
      document.getElementsByClassName("modal")[0].addEventListener('click', function(e) 
        {
          if (!e.target.classList.contains('modal-content')) {
            onClose();
          }
        }
      );
    

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.getElementById("homepage").classList.remove('blurred');
    }};
    
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal">
    <div className="modal-content">
        <div className='close-row'>
        <div className="close" onClick={onClose}> 
        <img alt='' src={process.env.PUBLIC_URL + '/assets/main/blue-left-arrow.svg'}/>
        Back
        </div>
        </div>
        <div className='modal-header'>
        <img src={process.env.PUBLIC_URL + '/assets/main/people/' + ModalContent[person]['pic']} alt=""/>
        <div className='modal-name'>
        <h2>{ModalContent[person]['fname']}</h2>
        <span className='modal-line'></span>
        <h4>{ModalContent[person]['position']}</h4>
        </div>
        </div>
        <div className='modal-paragraph'>
            <p className='modal-bio'>
              {ModalContent[person]['bio']}
            </p>
        </div>
    </div>
    </div>
  );
};

export default Modal;

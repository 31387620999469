import { useEffect, useState, useLayoutEffect } from "react";
import headerimage from './images/vision.jpg'
import './Vision.css';

function Vision(){

    const [imageHeight, setImageHeight] = useState(0);
    const topPosition = imageHeight * 0.7;

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const handleResize  = () => {
        const image = document.getElementsByClassName('vision-header-image')[0];
        if (image) {
          setImageHeight(image.offsetHeight);
        }}

        useEffect(() => {
            handleResize();
        
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        })

    return(
        <div className="Vision">
             <div className='vision-header'>
                <img src={headerimage} className='vision-header-image' alt=""/>
                <div className='vision-header-wrapper'
                style={{ top: `${topPosition}px` }}
                >
                <div className='vision-header-title'>
                    <h2>Our Vision</h2>
                </div>
                </div>
            </div>
            <div className='vision-content'>
                <div className="vision-intro">
                    <h4>Navigating Global Market Challenges with Strategic Insight</h4>
                    <p>In today’s increasingly volatile global market, driving growth and seizing opportunities require a fresh perspective. Success demands prioritizing facts, questioning prevailing narratives, and executing strategies with precision and passion. Our stakeholders expect us to deliver, diversify, and scale our operations to adapt swiftly to changes while achieving their financial goals.
                    <br />
                    <br />
                    At the core of our strategy is a prudent and pragmatic approach to risk management. We secure and collateralize assets through proven investment classes, ranging from cash collateralization to a diversified property portfolio. By leveraging the stability of traditional wealth-generating assets, we enhance security and integrate cutting-edge technologies. This approach enables us to transform global market turbulence into financial opportunities, ensuring sustainable growth and success.
</p>
                </div>
                <div className="vision-main">
                    <p className="vision-main-one">In a world where change is the only constant, recent years have demonstrated just how quickly global dynamics can shift. Geopolitical uncertainties, the rise of AI, demographic challenges, climate changes, and the evolving post-war economic order have created a volatile environment. Navigating this complex landscape demands a perspective grounded in realism.
                    <br />
                    <br />
                    We believe that success in this new economic reality requires an organization that prioritizes both methods and results. Integrity is at the heart of our approach, where decisions are driven by rigorous analysis and facts. We challenge assumptions, relying on empirical data to inform our strategies and ensure that every action is backed by a solid foundation of evidence. In our view, nothing should be accepted as certain without thorough validation and a clear understanding of the principles that underpin it.</p>
                    <p className="vision-main-two">At Aptius, our approach is grounded in prioritizing facts, embracing a healthy skepticism, and recognizing that shifts in the global economy demand a shift in perspective. We view change not as a challenge, but as a valuable asset. By questioning assumptions, understanding the data, and seizing opportunities, we focus on driving growth as our top priority. 
                    <br />
                    <br />
                    Our ultimate goal is to position Aptius not only as a catalyst for commercial growth but also as a beacon for fostering a new, empirical mindset. We encourage critical thinking, where important questions are asked, assertions are tested, and decisions are driven by evidence. This mindset forms the foundation of our vision—creating an organization that prioritizes results, challenges conventional narratives, and operates with both efficiency and zeal.</p>
                    <p className="vision-main-three">In essence, our vision for Aptius is to build an organization rooted in realism, guided by analysis, and committed to growth and delivering results in an ever-changing world. </p>    
                </div>
            </div>
        </div>
    )
}

export default Vision;
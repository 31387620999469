import { useState, useEffect, useLayoutEffect } from 'react';
import './Risk.css';
import headerimage from './images/header-background.jpg';
import Promo from '../../components/promo/Promo';

function Risk(){

    const [imageHeight, setImageHeight] = useState(0);
    const topPosition = imageHeight * 0.7;

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const handleResize  = () => {
        const image = document.getElementsByClassName('risk-header-image')[0];
        if (image) {
          setImageHeight(image.offsetHeight);
        }}

        useEffect(() => {
            handleResize();
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        })

    return(
        <div className='Risk'>
            <div className='risk-header'>
                <img src={headerimage} className='risk-header-image' alt=""/>
                <div className='risk-header-wrapper'
                style={{ top: `${topPosition}px` }}
                >
                <div className='risk-header-title'>
                    <h2>Security & Risk</h2>
                </div>
                </div>
            </div>
            <div className='risk-content'>
                <div className='risk-intro'>
                    <h4> Resilience Through Robust Collateralization </h4>
                    <p>At Aptius, we prioritize resilience by utilizing segmented collateral entities, enabling low-risk trading across diverse markets. Our market-agnostic approach leverages advanced AI-driven analytics, processing $1 trillion in market capital volume over the last decade. This real-time intelligence guides automated decisions, minimizing human error and mitigating risk. </p>
                </div>
                <div className='risk-main'>
                    <span className='risk-main-one'></span>
                    <span className='risk-main-two'>
                    All stakeholder holdings are fully collateralized, ensuring security and supporting the unique needs of each partner. Since 2018, we’ve collaborated with commercial organizations, NGOs, high-net-worth individuals, and government departments, consistently delivering results with a strong track record.
                    <br />
                    Our multi-layered risk management strategy includes the Information Gathering Centre (IGC), which combines the expertise of our team, external specialists, and stakeholders to stay ahead of global volatility. The IGC integrates Big Data and advanced analytics to optimize trading performance.
                    <br />
                    <br />
                    We have heavily invested in AI and algorithms, aiming for tenfold growth in the next five years. Our security measures include regular penetration testing, SOC 2 compliance for vendors, and robust disaster recovery frameworks, ensuring zero medium or high-risk vulnerabilities.
                    <br />
                    <br />
                    As we expand our holdings and welcome global investment, our diversification strategy continues to lower risk and broaden our network, with key stakeholders in South America, Europe, and the Emirates.
                    </span>
                    <span className='risk-main-three'></span>
                </div>
                    <Promo section={'riskend'}/>
            </div>
        </div>
    )
}

export default Risk;
import { useLayoutEffect, useState, useEffect } from 'react';
import axios from 'axios';
import headerimage from './images/header-background.png';
import desktopimage from './images/contact.jpg';
import './Contact.css'; 

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [notification, setNotification] = useState(null); // Notification state
    const [notificationType, setNotificationType] = useState(''); // Notification type state
    const [imageHeight, setImageHeight] = useState(0);
    const topPosition = imageHeight * 0.7;

    const handleResize = () => {
        const image = document.getElementsByClassName('contact-header-image')[0];
        if (image) {
            setImageHeight(image.offsetHeight);
        }
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const validateForm = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^[0-9]*$/;

        if (!name) {
            setNotification('Name is required');
            setNotificationType('error');
            return false;
        }
        if (!email || !emailPattern.test(email)) {
            setNotification('Valid email is required');
            setNotificationType('error');
            return false;
        }
        if (!phone || !phonePattern.test(phone)) {
            setNotification('Valid phone number is required');
            setNotificationType('error');
            return false;
        }
        if (!message) {
            setNotification('Message is required');
            setNotificationType('error');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent default form submission behavior

        if (!validateForm()) {
            // Hide notification after 5 seconds for validation errors
            setTimeout(() => setNotification(null), 5000);
            return;
        }

        setLoading(true); // Set loading state to true

        const nameParts = name.split(' ');
        const firstname = nameParts[0] || '';
        const lastname = nameParts.slice(1).join(' ') || '';

        const postData = {
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone,
            message: message
        };

        console.log(postData);

        try {
            const response = await axios.post('https://conventusdigital.co.uk/php_scripts/contact.php', postData);
            console.log('Response:', response.data);
            // Handle successful response
            setNotification('Message sent successfully!');
            setNotificationType('success');
            // Clear form values
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
        } catch (error) {
            console.error('Error:', error);
            // Handle error response
            setNotification('Something went wrong. Please try again.');
            setNotificationType('error');
        } finally {
            setLoading(false); // Set loading state to false
            // Hide notification after 10 seconds
            setTimeout(() => setNotification(null), 10000);
        }
    };

    return (
        <div className="Contact">
            <div className='contact-header'>
                <img src={headerimage} className='contact-header-image' alt=""/>
                <div className='contact-header-wrapper' style={{ top: `${topPosition}px` }}>
                    <div className='contact-header-title'>
                        <h2>contact</h2>
                    </div>
                </div>
            </div>
            <div className='contact-body'>
                <img src={desktopimage} className='contact-image' alt=""/>
                {notification && (
                    <div className={`notification ${notificationType}`}>
                        {notification}
                    </div>
                )}
                <form className='contact-form'>
                    <div>
                        <label htmlFor='name'>Name*</label>
                        <input id='name' placeholder='Enter name' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor='email'>Email*</label>
                        <input id='email' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor='phoneNo'>Contact Telephone*</label>
                        <input type='tel' id='phoneNo' placeholder='Enter telephone number' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor='message'>Message*</label>
                        <textarea id='message' placeholder='Type here..' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                    <button className='submitBtn' onClick={handleSubmit} disabled={loading}>
                        {loading ? 'Sending...' : 'Send'}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Contact;

import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './layouts/navbar/Navbar';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Footer from './layouts/footer/footer';
import Technology from './pages/Technology/Technology';
import Governance from './pages/Governance/Governance';
import Risk from './pages/Risk/Risk';
import Charity from './pages/Charity/Charity';
import Vision from './pages/Vision/Vision';

function App() {
  return (
    <>
    <BrowserRouter>
    <Navbar />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/vision" element={<Vision />}/>
        <Route path="/technology" element={<Technology />}/>
        <Route path="/governance" element={<Governance />}/>
        <Route path="/risk" element={<Risk />}/>
        <Route path="/charity" element={<Charity />}/>
        <Route path="/contact" element={<Contact />}/>
      </Routes>
      <Footer />
    </BrowserRouter>
    </>
  );
}

export default App;

import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ourvisionimage from './images/vision.jpg';
import techimage from './images/technology.jpg';
import governanceimage from './images/parliament.jpg';
import riskimage from './images/chess.jpg';
import riskfinalimage from './images/risk-two.png';
import './Promo.css';

const Promo = ({ section }) => {
  const content = {
    ourvision: {
      header: "Our vision",
      content: "Discover our vision: to build an organization that embraces a pragmatic approach, where every action is underpinned by thorough analysis, and where our commitment to growth and results drives us to excel in a rapidly evolving world.",
      image: ourvisionimage,
      index: 0,
      pos: "right",
      link: '/vision'
    },
    technology: {
      header: "Technology",
      content: "Rooted in the private banking sector and shaped by expertise in FX, crypto, and computer programming, our proprietary software has been evolving since 2018. With over 10,000 hours of trading experience and more than 100,000 lines of code, we have developed the latest version of the Aptius platform, reflecting our commitment to innovation and precision.",
      image: techimage,
      index: 1,
      link: '/technology'
    },
    governance: {
      header: "Governance",
      content: "At Aptius, governance is a cornerstone of our culture and values. We strive to exceed regulatory standards, not only to ensure security and compliance but also to maintain the highest levels of accountability. Our commitment to professionalism, rigorous standards, and transparent collateralization policies reflects our dedication to responsible and ethical business practices.",
      image: governanceimage,
      index: 2,
      link: '/governance'
    },
    risk: {
      header: "Security and Risk",
      content: "Our approach to security and risk management is built on a solid foundation of collateral. Our protection policies are reinforced by substantial holdings and underpinned by rigorous security protocols. While no success rate can be guaranteed at 100%, what truly matters is the creation of a security buffer that safeguards all our operations. This approach has resulted in a strong six-year track record, demonstrating our commitment to a prudent and effective risk management strategy.",
      image: riskimage,
      index: 3,
      link: '/risk'
    },
    riskend: {
      content: "With significant expansion of company holdings and organic growth we have now decided to broaden our horizons and welcome new global investment; with key stakeholders in South America, Europe and the Emirates. Diversification of the markets in which we operate both expands our network and ultimately lowers risk further.",
      image: riskfinalimage,
      index: 0
    }
  };

  const [dimensions, setDimensions] = useState({ imageHeight: 0, textHeight: 0, imageTop: 0, imageBottom: 0, textBottom: 0 });

  const updateDimensions = useCallback(() => {
    const image = document.getElementsByClassName('promo-img')[content[section].index];
    const text = document.getElementsByClassName('promo-text')[content[section].index];

    if (image && text) {
      const imageHeight = image.offsetHeight;
      const imageTop = image.getBoundingClientRect().top + window.scrollY;
      const imageBottom = imageTop + imageHeight;
      const textHeight = text.offsetHeight;
      const textBottom = text.getBoundingClientRect().top + window.scrollY + textHeight;

      setDimensions({ imageHeight, textHeight, imageTop, imageBottom, textBottom });
    }
  }, [section]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  useEffect(() => {
    const promoText = document.querySelectorAll('.promo-text');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    promoText.forEach(text => observer.observe(text));
    return () => observer.disconnect();
  }, []);

  const { imageHeight, textHeight, imageTop, imageBottom, textBottom } = dimensions;
  const topPosition = imageHeight * 0.5;

  const getTextTop = () => {
    if (window.innerWidth < 1025) return `${topPosition}px`;
    switch (section) {
      case 'ourvision':
        return `${imageHeight * 0.2}px`
      case 'technology':
        return `${imageHeight * 0.1}px`;
      case 'governance':
        return `${imageHeight * 0.1}px`;
      case 'risk':
        return `${imageHeight * 0.1}px`;
      default:
        return `${imageHeight * 0.1}px`;
    }
  };

  return (
    <div className='promo'
      style={window.innerWidth < 1025 ? 
        { height: `${imageHeight + textHeight}px` } 
        : 
        { height: `${Math.max(imageBottom, textBottom) - imageTop}px` }
      }
      data-promo-section={section}
    >
      <img src={content[section].image} className='promo-img' data-promo-section={section} alt="" />
      <div className='promo-text' data-promo-section={section} style={{ top: getTextTop() }}>
        {content[section].header && <h4>{content[section].header}</h4>}
        <p style={content[section].link ? { marginBottom: '5vh' } : null}>{content[section].content}</p>
        {content[section].link && <Link to={content[section].link}>Read More</Link>}
      </div>
    </div>
  );
};

export default Promo;
  